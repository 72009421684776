<template>
  <div>
    <query-form
      ref="queryRef"
      :query-form.sync="queryAward"
      :form="form"
    >
      <template v-slot:buttons>
        <b-button
          variant="primary"
          class="mr-1"
          @click="() => {currentPage = 1;search()}"
        >Search</b-button>
        <b-button
          variant="secondary"
          class="mr-1"
          @click="reset"
        >Reset</b-button>
        <b-button
          variant="success"
          class=""
          @click="addPrize"
        >+New</b-button>
      </template>
    </query-form>

    <table-list
      :table-data.sync="tableData"
      :list.sync="tableList"
      :page-num.sync="currentPage"
      :total-list.sync="totalList"
    />

    <!-- New User -->
    <b-modal
      ref="newPrizeModel"
      :title="newPrizeTitle"
      ok-title="Save"
      cancel-variant="outline-secondary"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      centered
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <b-overlay
        :show="addPrizeLoading"
      >
        <validation-observer
          ref="addPrizeForm"
        >
          <b-form class="mt-1">
            <b-form-group
              label="Group Name"
              label-for="groupName"
              label-cols-md="4"
            >
              <validation-provider
                #default="{ errors }"
                name="Group Name"
                vid="groupName"
                rules="required"
              >
                <b-form-input
                  id="groupName"
                  v-model="groupNameModel"
                  placeholder="Group Name"
                  autocomplete="off"
                  oninput="if(value.length > 50)value = value.slice(0, 50)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Number of Codes"
              label-for="numberOfCodes"
              label-cols-md="4"
            >
              <validation-provider
                #default="{ errors }"
                name="Number of Codes"
                vid="numberOfCodes"
                rules="required"
              >
                <b-form-input
                  id="numberOfCodes"
                  v-model="numberOfCodesModel"
                  type="number"
                  placeholder="Number of Codes"
                  autocomplete="off"
                  oninput="if(Number(value)>1000){value=1000};if(Number(value)<=0){value=0};value=Number(value)"
                  trim
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Code Value"
              label-for="codeValue"
              label-cols-md="4"
            >
              <validation-provider
                #default="{ errors }"
                name="Code Value"
                vid="codeValue"
                rules="required"
              >
                <b-input-group>
                  <b-input-group-prepend>
                    <b-dropdown
                      :text="currencyModal.label"
                      variant="outline-primary"
                    >
                      <b-dropdown-item
                        v-for="(item, index) in currencyModalOptions"
                        :key="index"
                        @click="currencyModalChange(item)"
                      >
                        {{ item.label }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-input-group-prepend>
                  <b-form-input
                    id="codeValue"
                    v-model="codeValueModal"
                    placeholder="$..."
                    autocomplete="off"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Event Expiry Time"
              label-for="eventExpiryTime"
              label-cols-md="4"
            >
              <validation-provider
                #default="{ errors }"
                name="Event Expiry Time"
                vid="eventExpiryTime"
                rules="required"
              >
                <date-picker
                  id="eventExpiryTime"
                  v-model="eventExpiryModal"
                  :range="true"
                  :time-range-default="eventExpiryDefault"
                  :disabled-before-time="true"
                  @TimeRange="getTimeRange"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Description"
              label-for="description"
              label-cols-md="4"
            >
              <b-form-textarea
                id="description"
                v-model="descriptionModel"
                placeholder="Description"
                autocomplete="off"
                rows="3"
                oninput="if(value.length > 100)value = value.slice(0, 100)"
              />
            </b-form-group>
          </b-form>
        </validation-observer>
      </b-overlay>
    </b-modal>

    <!-- End Promotion Event -->
    <b-modal
      ref="endPromotionEvent"
      centered
      title="End Promotion Event"
      hide-footer
    >
      <div class="end-promotion-event">
        <div class="end-promotion-p">
          This action will result in all {{ groupNameText }} codes in the "Waiting Submission" status being marked expired.
        </div>
        <div class="end-promotion-p">
          Please ensure you have authorisation before continuing.
        </div>
        <div class="btn-groups">
          <b-button
            class="mt-2 mb-2 mr-4"
            variant="outline-primary"
            @click="backFunc"
          >
            Back
          </b-button>
          <b-button
            class="mt-2 mb-2"
            variant="primary"
            @click="endFunc"
          >
            END
          </b-button>
        </div>
      </div>
    </b-modal>

    <!-- Email -->
    <!-- @show="resetSendEmailModal" -->
    <b-modal
      ref="sendEmailModel"
      title="Send Mass Email"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :hide-footer="true"
      centered
      @hidden="resetSendEmailModal"
    >
      <b-overlay
        :show="sendEmailLoading"
      >
        <validation-observer
          ref="sendEmailForm"
        >
          <b-form class="mt-1 pt-1 pl-2 pr-2 pb-1">
            <b-form-group
              label="Upload Parameters"
              label-for="uploadParameters"
            >
              <validation-provider
                #default="{ errors }"
                name="Upload Parameters"
                vid="uploadParameters"
                rules="required"
              >
                <b-form-file
                  id="uploadParameters"
                  v-model="parametersFile"
                  placeholder="Upload Parameters"
                  accept=".csv, .xlsx, .xls"
                  @input="uploadFile"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <div
              v-if="parametersFile"
              class="mt-2"
            >
              <p>Volume:</p>
              <b-table
                bordered
                responsive="sm"
                :items="[{avaliable: avaliableCount,ready_to_send: readyCount}]"
                :fields="[{ key: 'avaliable', label: 'avaliable', class: 'text-center' },{ key: 'ready_to_send', label: 'ready to send', class: 'text-center' }]"
              />
              <div>By clicking "send" below, emails will beautomatically sent to users. </div>
              <div>This action cannot be undone!</div>
            </div>
          </b-form>
        </validation-observer>
        <div class="pt-2 pb-2 text-center">
          <b-button
            variant="primary"
            :disabled="!uploadKey"
            @click="handleSendEmailOk"
          >
            Send
          </b-button>
        </div>
      </b-overlay>
    </b-modal>

  </div>
</template>
<script>
import {
  defineComponent, ref, getCurrentInstance, computed, watch,
} from '@vue/composition-api'
import {
  BButton, BOverlay, BFormGroup, BFormInput, BFormTextarea,
  BForm, BDropdown,
  BDropdownItem, BInputGroup,
  BInputGroupPrepend, BFormFile, BTable,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import DatePicker from '@/component/Datepicker.vue'
import { showToast } from '@/libs/utils'

export default defineComponent({
  setup() {
    const { proxy } = getCurrentInstance()
    const { $store } = proxy
    // query
    const queryAward = ref({})
    const form = ref([
      {
        label: 'Group Name', itype: 'input', imodel: 'groupName', placeholder: 'Enter search terms',
      },
      {
        label: 'Creation Date', itype: 'datetime', imodel: 'timeRangeDefault', placeholder: '',
      },
    ])
    const timeZone = computed(() => $store.state.wallet.timeZone, { immediate: true, deep: true })
    // table
    const currentPage = ref(1)
    const tableList = ref([])
    const totalList = ref(0)
    const groupNameText = ref('')
    const currentGroupId = ref('')
    const download = async gdata => {
      if (!gdata.group_id) return
      const params = {
        group_id: gdata.group_id,
        download: true,
      }
      proxy.$showLoading()
      const res = await proxy.$api.fetchBonusGroupList(params)
      proxy.$hideLoading()
      const { code, message } = res.data
      if (code === 200) {
        proxy.$router.push({ name: 'Download' })
        // showToast(proxy, 'Notification', 'Download Success', 'success')
      } else {
        showToast(proxy, 'Notification', `${message}`, 'danger')
      }
    }

    const generatorDelete = (type, gdata) => {
      // console.log(type, gdata)
      if (type === 'delete') {
        groupNameText.value = gdata.group_name
        currentGroupId.value = gdata.group_id
        proxy.$refs.endPromotionEvent.show()
      } else if (type === 'download') {
        download(gdata)
      } else if (type === 'email') {
        sendEmail(gdata)
      } else {}
    }
    const tableData = ref({
      tableColumns: [
        { key: 'group_name', label: 'GROUP NAME' },
        { key: 'description', label: 'DESCRIPTION' },
        { key: 'created_time', label: 'CREATION DATE' },
        { key: 'expiry_time', label: 'EXPIRY' },
        { key: 'max_count', label: 'NUMBER OF CODES' },
        { key: 'distributed_count', label: 'DISTRIBUTED CODES' },
        { key: 'amount', label: 'CODE VALUE' },
        { key: 'currency', label: 'CURRENCY' },
        { key: 'operation', label: 'OPERATIONS' },
      ],
      templates: [
        { key: 'created_time', tType: 'prize_distribution_datetime' },
        { key: 'expiry_time', tType: 'prize_distribution_datetime' },
        { key: 'operation', tType: 'prize_distribution_delete', callback: generatorDelete },
      ],
    })

    const currentPrizeId = ref()
    const newPrizeTitle = ref()
    const currencyModalOptions = ref([
      { label: 'BRL', value: 'BRL' }, { label: 'MXN', value: 'MXN' },
    ])
    const groupNameModel = ref('')
    const numberOfCodesModel = ref('')
    const currencyModal = ref({ label: 'BRL', value: 'BRL' })
    const codeValueModal = ref('')
    const eventExpiryModal = ref([])
    const eventExpiryDefault = ref([])
    const descriptionModel = ref('')

    const addPrizeLoading = ref(false)

    // func
    const search = async () => {
      // console.log('queryRecordForm.value', JSON.stringify(queryAward.value))
      const params = {
        group_name: queryAward.value.groupName,
        page_no: currentPage.value,
        page_size: 10,
      }
      if (queryAward.value.timeRangeDefault) {
        params.created_time_scope = {};
        [params.created_time_scope.start_date, params.created_time_scope.end_date] = queryAward.value.timeRangeDefault
      }
      proxy.$showLoading()
      const res = await proxy.$api.fetchBonusGroupList(params)
      proxy.$hideLoading()
      const { data } = res
      if (data.code === 200) {
        const { list, pagination } = data.data
        tableList.value = list
        totalList.value = pagination.total_count
      }
    }

    const reset = () => {
      queryAward.value.timeRangeDefault = []
      proxy.$refs.queryRef.reset()
    }
    const addPrize = () => {
      currentPrizeId.value = null
      newPrizeTitle.value = 'New Promotion'
      proxy.$refs.newPrizeModel.show()
    }
    const resetModal = () => {
      groupNameModel.value = ''
      numberOfCodesModel.value = ''
      currencyModal.value = { label: 'BRL', value: 'BRL' }
      codeValueModal.value = ''
      eventExpiryModal.value = []
      eventExpiryDefault.value = []
      descriptionModel.value = ''
    }
    const handleSubmit = callback => {
      proxy.$refs.addPrizeForm.validate().then(async success => {
        if (success) {
          const params = {
            group_name: groupNameModel.value,
            max_count: numberOfCodesModel.value,
            amount: codeValueModal.value,
            // max_count: Number(numberOfCodesModel.value),
            // amount: Number(codeValueModal.value),
            currency: currencyModal.value.value,
            expiry_time: `${eventExpiryModal.value[0]}`,
            description: descriptionModel.value,
          }
          proxy.$showLoading()
          const res = await proxy.$api.fetchBonusGroupSave(params).catch(() => {
            proxy.$hideLoading()
          })
          proxy.$hideLoading()
          const { code, data, message } = res.data
          if (code === 200) {
            currentPrizeId.value = null
            proxy.$refs.newPrizeModel.hide()
            showToast(proxy, 'Notification', 'Success', 'success')
            callback()
          } else {
            showToast(proxy, 'Notification', `${message}`, 'danger')
          }
        }
      })
    }
    const handleOk = bvModalEvt => {
      bvModalEvt.preventDefault()
      handleSubmit(() => {
        currentPage.value = 1
        search()
      })
    }
    watch([currentPage], async () => { await search() })
    const currencyModalChange = value => {
      currencyModal.value = value
    }
    const getTimeRange = time => {
      eventExpiryModal.value = time
    }
    const backFunc = () => { proxy.$refs.endPromotionEvent.hide() }
    const endFunc = async () => {
      proxy.$refs.endPromotionEvent.hide()
      proxy.$showLoading()
      const res = await proxy.$api.fetchBonusGroupRemove({ group_id: currentGroupId.value })
      proxy.$hideLoading()
      const { code, message } = res.data
      if (code === 200) {
        currentPage.value = 1
        search()
        showToast(proxy, 'Notification', 'Delete Success', 'success')
      } else {
        showToast(proxy, 'Notification', `${message}`, 'danger')
      }
    }

    // ** Email Modal 逻辑 **
    const parametersFile = ref(null)
    const sendEmailLoading = ref(false)
    const uploadKey = ref('')
    const avaliableCount = ref('')
    const readyCount = ref('')
    const sendEmail = gdata => {
      proxy.$refs.sendEmailModel.show()
      console.log('current sendEmail bean', gdata)
    }
    const resetSendEmailModal = () => {
      parametersFile.value = null
      uploadKey.value = ''
      avaliableCount.value = ''
      readyCount.value = ''
      sendEmailLoading.value = false
    }
    const uploadFile = async file => {
      const formdata = new FormData()
      formdata.append('file', file)
      sendEmailLoading.value = true
      const res = await proxy.$api.templateUpload(formdata)
      sendEmailLoading.value = false
      const { code, data, message } = res.data
      if (code === 200) {
        const { upload_key: uploadKeyValue, avaliable_count: avaliableCountValue, ready_count: readyCountValue } = data
        uploadKey.value = uploadKeyValue
        avaliableCount.value = avaliableCountValue
        readyCount.value = readyCountValue
      } else {
        showToast(proxy, 'Notification', `${message}`, 'danger')
      }
    }

    const handleSendEmailOk = () => {
      // uploadKey
      proxy.$refs.sendEmailForm.validate().then(async success => {
        if (success) {
          sendEmailLoading.value = true
          const params = { upload_key: uploadKey.value }
          const res = await proxy.$api.bonusCodeSend(params).catch(error => {
            const { message } = error
            sendEmailLoading.value = false
            showToast(proxy, 'Notification', `${message}`, 'danger')
          })
          if (!res) return
          const { code, message } = res.data
          if (code === 200) {
            sendEmailLoading.value = false
            proxy.$refs.sendEmailModel.hide()
            showToast(proxy, 'Notification', 'Send Email Success', 'success')
            currentPage.value = 1
            search()
          } else {
            showToast(proxy, 'Notification', `${message}`, 'danger')
          }
        }
      })
    }

    return {
      queryAward,
      form,
      timeZone,
      tableData,
      tableList,
      currentPage,
      totalList,
      groupNameText,
      search,
      reset,
      addPrize,
      resetModal,

      currentPrizeId,
      newPrizeTitle,
      currencyModalOptions,
      groupNameModel,
      numberOfCodesModel,
      currencyModal,
      codeValueModal,
      eventExpiryModal,
      eventExpiryDefault,
      descriptionModel,

      addPrizeLoading,

      handleOk,
      currencyModalChange,
      getTimeRange,

      required,
      email,

      backFunc,
      endFunc,

      // Email 逻辑
      parametersFile,
      sendEmailLoading,
      resetSendEmailModal,
      handleSendEmailOk,
      uploadFile,
      uploadKey,
      avaliableCount,
      readyCount,
    }
  },
  components: {
    BButton,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BDropdown,
    BDropdownItem,
    BInputGroup,
    BInputGroupPrepend,
    BFormFile,
    BTable,

    ValidationProvider,
    ValidationObserver,
    vSelect,
    DatePicker,
  },
})
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.end-promotion-event {
  padding: 0 20px;
  .end-promotion-p {
    margin: 10px 0 0 10px;
    font-weight: 500;
        font-size: 16px;
  }
  .btn-groups {
    display: flex;
    justify-content: center;
  }
}

</style>
